import {CommonModule} from '@angular/common';
import { NgModule } from '@angular/core';
import {
  ToMoneyPipe,
  RelativeTimePipe,
  SafeUrlPipe,
  ToMoneyLocalPipe,
  ToPercentPipe,
  ToIntPercentPipe,
  HistoryStatusPipe,
  TimestampToDatePipe
} from './casino/helpers/pipes';

@NgModule({
  imports: [CommonModule],
  declarations: [
    SafeUrlPipe,
    RelativeTimePipe,
    ToMoneyPipe,
    ToMoneyLocalPipe,
    ToPercentPipe,
    ToIntPercentPipe,
    HistoryStatusPipe,
    TimestampToDatePipe
  ],
  exports: [
    SafeUrlPipe,
    RelativeTimePipe,
    ToMoneyPipe,
    ToMoneyLocalPipe,
    ToPercentPipe,
    ToIntPercentPipe,
    HistoryStatusPipe,
    TimestampToDatePipe
  ]
})
export class AppSharingModule {}
