export default class PaymentWithdrawStatus {

  public Created = 10;
  public ManualValidation = 15;
  public Approved = 20;
  public Sent = 30;
  public Confirmed = 40;
  public Failed = 50;

  private STATUSES = {
    10: 'Created',
    15: 'Manual validation',
    20: 'Approved',
    30: 'Sent',
    40: 'Confirmed',
    50: 'Failed'
  };

  public statusById(key): any {
    if (key in this.STATUSES) {
      return this.STATUSES[key];
    }
    return '';
  }

  public getStatuses(): any {
    return this.STATUSES;
  }

}
