import { JsonProperty, JsonObject } from 'json2typescript';

@JsonObject('Balance')
export class Balance {
  @JsonProperty('id', Number)
  id: number = undefined;
  @JsonProperty('balance', Number)
  balance: number = undefined;
  @JsonProperty('currency', String)
  currency: string = undefined;
  @JsonProperty('cash_back', Number)
  cashBack: number = undefined;
}
