import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {DecimalPipe} from '@angular/common';
import Utils from './utils';
import PaymentWithdrawStatus from './payment-withdraw-status';
import PaymentDepositStatus from './payment-deposit-status';
import * as moment from 'moment';


@Pipe({
  name: 'safeUrl'
})
export class SafeUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
  }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Pipe({
  name: 'relativeTime',
  pure: true
})
export class RelativeTimePipe implements PipeTransform {

  transform(value: any): any {
    if (value) {
      const differenceInSeconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      if (differenceInSeconds < 29) {
        return 'Just now';
      }
      // All values are in seconds
      const timeIntervals = {
        year: 31536000,
        month: 2592000,
        week: 604800,
        day: 86400,
        hour: 3600,
        minute: 60,
        second: 1
      };
      let counter;
      for (const i in timeIntervals) {
        if (!timeIntervals.hasOwnProperty(i)) {
          continue;
        }
        counter = Math.floor(differenceInSeconds / timeIntervals[i]);
        if (counter > 0) {
          if (counter === 1) {
            return counter + ' ' + i + ' ago';
          } else {
            return counter + ' ' + i + 's ago';
          }
        }
      }
    }
    if (value === null || value === '') {
      return 'never';
    }
    return value;
  }
}

@Pipe({
  name: 'toMoneyLocal',
  pure: true
})
export class ToMoneyLocalPipe implements PipeTransform {

  constructor(private decimalPipe: DecimalPipe) {
  }

  transform(value: any, args?: any): any {
    if (value) {
      return this.decimalPipe.transform(value / 100, '1.2');
    }
    return this.decimalPipe.transform(0, '1.2');
  }
}


@Pipe({
  name: 'toMoney',
  pure: true
})
export class ToMoneyPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return Utils.toMoney(value);
  }
}


@Pipe({
  name: 'toPercent',
  pure: true
})
export class ToPercentPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return Utils.toPercent(value);
  }
}

@Pipe({
  name: 'toIntPercent',
  pure: true
})
export class ToIntPercentPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return Utils.toIntPercent(value);
  }
}


@Pipe({
  name: 'historyStatus',
  pure: true
})
export class HistoryStatusPipe implements PipeTransform {
  transform(status: any, type: any): any {
    const withdrawStatus = new PaymentWithdrawStatus();
    const depositStatus = new PaymentDepositStatus();
    switch (type) {
      case 10:
        return depositStatus.statusById(status);
      case 20:
        return withdrawStatus.statusById(status);
      default:
        return '';
    }
  }
}


@Pipe({
  name: 'timestampToDate',
  pure: true
})
export class TimestampToDatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return moment.unix(value / 1000).format('YYYY-MM-DD HH:mm:ss');
  }
}
