// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  name: 'Million',
  skin: 'million-red',
  domain: 'https://million-casino.games',
  emails: {
    affiliates: 'affiliates@million-casino.games',
    support: 'support@million-casino.games',
    dataprotection: 'dataprotection@million-casino.games',
    dataprivacy: 'dataprivacy@million-casino.games'
  },
  sentry: {
    dns: 'https://abc0ea7da27d4d3eaef1c215492963bc@o1202490.ingest.sentry.io/6327766'
  },
  freshChat: {
    host: 'https://wchat.eu.freshchat.com',
    token: 'a060897c-6728-43d2-8846-a72c9513491b'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
