export default class PaymentDepositStatus {

  private STATUSES = {
    10: 'Created',
    20: 'Checked',
    25: 'Sent',
    30: 'Confirmed',
    100: 'Failed'
  };

  public statusById(key): any {
    if (key in this.STATUSES) {
      return this.STATUSES[key];
    }
    return '';
  }

  public getStatuses(): any {
    return this.STATUSES;
  }

}
