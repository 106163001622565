import {environment} from '../environments/environment';
import * as Sentry from '@sentry/browser';

import {BrowserModule} from '@angular/platform-browser';
import {NgModule, ErrorHandler, Injectable} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {MatDialogModule} from '@angular/material/dialog';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule} from '@angular/material/snack-bar';
import {ReactiveFormsModule} from '@angular/forms';
import {ErrorInterceptor} from './casino/interceptors/error.interceptor';
import {MatIconModule} from '@angular/material/icon';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';
import {MatTableModule} from '@angular/material/table';
import {CdkTableModule} from '@angular/cdk/table';
import {NgxMaskModule} from 'ngx-mask';
import {FullscreenOverlayContainer, OverlayContainer} from '@angular/cdk/overlay';
import {MatPaginatorModule} from '@angular/material/paginator';
import {FormsModule} from '@angular/forms';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {NgxFreshChatModule} from 'ngx-freshchat';
import {MatTooltipModule} from '@angular/material/tooltip';
import {Ng9OdometerModule} from 'ng9-odometer';
import {ngxLoadingAnimationTypes, NgxLoadingModule} from 'ngx-loading';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {GoogleAnalyticsService} from './google-analytics.service';
import {NgHttpLoaderModule} from 'ng-http-loader';
import {AppSharingModule} from './app.sharing.module';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

Sentry.init({
  dsn: environment.sentry.dns
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {
  }

  handleError(error: any) {
    if (environment.production) {
      const exception = error.originalError || error.error || error.message || error;
      Sentry.captureException(exception);
    } else {
      console.error(error);
    }
  }
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppSharingModule,
    FormsModule,
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatGridListModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxIntlTelInputModule,
    MatTableModule,
    CdkTableModule,
    NgxMaskModule.forRoot(),
    MatPaginatorModule,
    MatRadioModule,
    MatInputModule,
    MatSelectModule,
    NgxFreshChatModule,
    MatTooltipModule,
    Ng9OdometerModule.forRoot(),
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.cubeGrid
    }),
    ClipboardModule,
    NgHttpLoaderModule.forRoot(),
    LazyLoadImageModule
  ],
  providers: [
    GoogleAnalyticsService,
    {provide: ErrorHandler, useClass: SentryErrorHandler},
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 2500, verticalPosition: 'top'}},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: OverlayContainer, useClass: FullscreenOverlayContainer},
    {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
