import {Component, OnInit} from '@angular/core';
import {AuthService} from './casino/services/auth.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {GoogleAnalyticsService} from './google-analytics.service';
import {environment} from '../environments/environment';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'casino-frontend';

  templateConfig = environment;
  userSub: Subscription;
  user: any;

  constructor(public authService: AuthService,
              private router: Router,
              private translate: TranslateService,
              private googleAnalyticsService: GoogleAnalyticsService,
              private activatedRoute: ActivatedRoute) {

    this.authService.info(true).subscribe();

    this.userSub = this.authService.currentUser.subscribe(user => {
      this.user = user;
      if (this.user) {
        /* check currency in balance */
        if (this.user && (!this.user.balances || this.user.balances.length === 0)) {
          this.router.navigate(['check-currency']);
        }
      }
    });

  }

  ngOnInit() {
    this.initTranslation();
    this.collectAnalytic();
  }

  initTranslation() {
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }

  collectAnalytic() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        /* google analytic  */
        if (environment.production) {
          if (event.urlAfterRedirects) {
            this.googleAnalyticsService.eventEmitter('pages', event.urlAfterRedirects, 'view');
          }
        }
      }
    });
  }

}
